.gallary_wrap{
    background-color: var(--blue-color);
    margin-left: -5vw;
        position: relative;
        margin-right: -5vw;
    // overflow-x: scroll;


    .gallary_counter{
        position: relative;
        top: 10%;
        left: 100px;
        z-index: 1;
        mix-blend-mode: difference;
        line-height: 16px;
        color: #dbd8d6;
        font-family: "Roboto";
        font-weight: 600;
        font-size: 16px;
        display: inline-block;

        .divider{
            content: "";
            background-color: white;
            width: 6.25vw;
            margin: 7px 10px;
            height: 1px;
            display: inline-block;
        }
    }

    .gallary{
        height: 80vh;
        padding: 10vh 0;
        width: 400%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .gallary_item{
        position: relative;
        width: 100%;
        height: 100%;
        will-change: transform;
    }
    
    .gallary_item_info{
        position: absolute;
        bottom: 10%;
        z-index: 1;
        transform: translateX(-20%);
        color: #dbd8d6;
        
        
        .gallary_info_title{
            line-height: 6vw;
            font-family: "Roboto";
            font-weight: 600;
            font-size: 6vw;
        }
        
        .gallary_info_subtitle{
            position: relative;
            line-height: 6vw;
            font-family: "Kalam";
            font-weight: 400;
            font-size: 6vw;
            color: transparent;
            -webkit-text-stroke: 2px var(--light-gray);
        }
        
        .gallary_info_category{
            position: relative;
            line-height: 24px;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 24px;
            margin-top: 24vh;
        }
        
        
    }
    .gallary_item_wrapper{
        aspect-ratio: 16/9;
        height: 100%;
        display: grid;
        grid-template-columns: 20vw 1fr 200px;
        width: 100vw;
    }

    .gallary_item_image{
        background-size: cover;
        background-position: center;
        transform-origin: center;
        width: 100%;
        height: 100%;
        will-change: transform;
        transform: scale(1);
        background-blend-mode: multiply;
        position: relative;
    }

    .gallary_item_image:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        background-color: #2c2c2c38;

    }
}