
.home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;

}

.header{
    /* position: absolute; */
    left: 0;
    background: url('https://res.cloudinary.com/midas-acadeny/image/upload/v1645792537/RCCG/239749098_6162580553783606_7636780945042574026_n_goofow.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-blend-mode: overlay; */
    overflow: hidden;
    color: white;
    width: 100vw;
min-height: 100vh;
    display: flex;
    background-position: center;
    justify-content: flex-start;
    flex-direction: column;
}



.header::after{
  background-color: #242732c4;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  height: 140%;
  width: 100%;
}

.bg-image{
    position: absolute;
}

.header_tab {
position: relative;
display: flex;
justify-content: center;
width: 100%;
align-items: flex-start;
z-index: 1;
}

.header_tab img{
    height: 120px;
    margin: 5vh;
    animation-duration: 1s;
  animation-name: fadeInDown;
  animation-delay: 1s;
  animation-fill-mode: both;
}

.header_content{
    color: white;
    display: flex;
    z-index: 1;
    /* position: relative; */
    justify-content: center;
    flex-direction: column;
}

.header_heading{
    font-size: 3rem;
    animation-duration: 1s;
    animation-name: fadeInDown;
    animation-delay: 2s;
    animation-fill-mode: both;
}

.header_heading strong{
    font-size: 3.2rem;
    font-family: "Kalam";
    color: var(--blue-color);
}

 blockquote strong{
    font-size: 2rem;
    font-family: "Kalam";
    color: var(--blue-color);
}

.header_sub_heading{
    font-size: 1.3rem;
    font-family: "Kalam", sans-serif;
    color: var(--light-gray);
    animation-duration: 1s;
    animation-name: fadeInDown;
    animation-delay: 3s;
    animation-fill-mode: both;
}

#registration-form{
    width: 500px;
      /* width: 70%; */
        /* background-color: rgba(82, 1, 102, .9); */
        /* padding: 50px; */
        max-width: 700px;
        margin: 150px auto;
        margin-top: 50px;
        width: 100%;
        animation-duration: 1s;
        animation-name: fadeInUp;
        animation-delay: 4s;
        animation-fill-mode: both;
}

.form_row{
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.form-control {
    position: relative;
    margin: 10px 0 20px;
    width: 45%;
    /* min-width: 50%; */
    flex-grow: 1;
    margin-right: 20px;
    background-color: white;
    transition: all .5s ease 0s;
    border-radius: 50px;
}

.form-control.num{
    display: flex;
    width: 100%;
}

.form-control.num select{
    border-radius: 50px;
    background-color: transparent;
    border: none;
    color: var(--dark-gray);
    max-width: 30%;
}

.address_state{
    display: flex;
    width: 100%;
}
.stateCode{
    border-radius: 50px;
    background-color: transparent;
    border: none;
    color: var(--dark-gray);
    max-width: 15%;
    margin-right: 15px;
}

.gender{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Kalam", sans-serif;
    color: var(--light-gray);

}

#female{
    margin-left: 20px;
    margin-right: 10px;
}

#male{
    margin-left: 20px;
    margin-right: 10px;

}



.form-control input {
    background-color: transparent;
    border: 0;
    /* border-bottom: 2px white solid; */
    display: block;
    width: 100%;
    padding: 15px 15px;
    font-size: 14px;
    color: var(--dark-gray);
    /* z-index: 10; */
}

.form-control label{
    position: absolute;
    right: 5%;
    top: 0;
    transform: translateY(50%);
    font-family: "Kalam", sans-serif;
    color: var(--dark-gray);
    /* font-weight: 500; */
    opacity: 1;

}

.day-date:focus .the-label{
    opacity: 0;

}


/* the check button */


.form-control2 {
    /* position: relative; */
    margin: 20px 0 40px;
    color: #53586b;
    width: 300px;
    margin-right: 20px;
    flex-grow: 1;
    z-index: 3;
    

}




.form-control.hide{
   height: 100%;
    transform: translateY(0);
    opacity: 1;
    z-index: 1
    
}

.form-control.anniversary, .form-control.anniversary input {
   color: var(--light-gray);
   border-color: var(--light-gray);
    transform: translateY(-100%);
    /* height: 0; */
   overflow: hidden;
   opacity: 0;
   z-index: -1;
}


.toggle-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}
.toggle-container span{
   font-size: 18px;
   color: var(--light-gray);
}





.labeled {
    background-color: var(--dark-gray);; 
    cursor: pointer;
    display: inline-block;
    border-radius: 50px;
    width: 80px;
    height: 40px;
    margin: 0 15px 0;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}

.labeled2 {
    background-color: var(--dark-gray);; 
    cursor: pointer;
    display: inline-block;
    border-radius: 50px;
    width: 80px;
    height: 40px;
    margin: 0 15px 0;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}


.ball {
    background-color: white;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: var(--dark-gray);
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    top: 3px;
    left: 3px;
    animation: slideOff 0.3s linear forwards;
    box-shadow: 0 0 6px rgba(0, 0, 0, .4), 0 0 10px rgba(0, 0, 0, .4);
}

.ball2 {
    background-color: white;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: var(--dark-gray);
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    top: 3px;
    left: 3px;
    animation: slideOff 0.3s linear forwards;
    box-shadow: 0 0 6px rgba(0, 0, 0, .4), 0 0 10px rgba(0, 0, 0, .4);
}

.toggle {
    visibility: hidden;
    position: absolute;
    left: 100px;
}
.toggle:checked + .labeled {
    /* background-color: #000000; */
    background-color: white;
}


.toggle:checked + .labeled .ball {
    animation: slideOn 0.3s linear forwards;
}

.toggle2 {
    visibility: hidden;
    position: absolute;
    left: 100px;
}
.toggle2:checked + .labeled2 {
    /* background-color: #000000; */
    background-color: white;
}


.toggle2:checked + .labeled2 .ball2 {
    animation: slideOn 0.3s linear forwards;
}

@keyframes slideOn {
    0%{
        transform: translateX(0) scale(1);
    }
    50%{
        transform: translateX(20px) scale(1.2);
    }
    100%{
        transform: translateX(40px) scale(1);
    }
}

@keyframes slideOff {
    0%{
        transform: translateX(40px) scale(1);
    }
    50%{
        transform: translateX(20px) scale(1.2);
    }
    100%{
        transform: translateX(0 ) scale(1);
    }
}



/* The end of the check button */

.form-control input:focus, .stateCode, .form-control.num select:focus{
    outline: none;
}

.form-control input:-webkit-autofill{

    background-color: black !important;
background-image: none !important;
color: -internal-light-dark(black, white) !important;
}

input:focus .form-control{
    border: solid 1px var(--blue-color);
}
.form-control input::-webkit-input-placeholder {
    font-family: "Kalam", sans-serif;
}

.form-control.dob, .ani{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.dob span, .ani span{
    color: var(--dark-gray);
    margin: 2px;
}
.day-date{
    width: 30px !important;
    /* border: black 1px solid !important; */
    padding: 15px 0 !important;
    padding-left: 15px !important;
}

.month-date{
    width: 25px !important;
    padding: 15px 0 !important;

}

.year-date{
    width: 40px !important;
    padding: 15px 0 !important;
 
}

.postcode{
    width: 80px !important;
}


.error-message {
    margin: 10px;
    text-align: center;
    color: var(--white-color);
}

.register-btn{
    font-weight: 600;
    text-decoration: none;
    border: 2px solid var(--blue-color);;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    color: white;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 3px;
    margin-top: 20px;
    margin-left: 0;
    padding: 12px 35px;
    background-color: var(--blue-color);
    cursor: pointer;
    width: 100%;
    transition: all 0.4s ease-out;
}

.register-btn:hover{
    opacity: 0.9;
    /* color: var(--dark-gray); */

}

.register-btn:hover:active{
    transform: scale(0.9);
    }

.about_rccg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    flex-direction: column;
}

.boxes{
    display: flex;
    justify-content: space-between;
    width: 70%;
    /* align-items: flex-start; */
    transition: all ease 1s;
    transform: translateY(-125px);
    
}

.box{
    position: relative;
    font-size: 1rem;
    color: var(--dark-gray);
    width: 33.3%;
    padding: 20px 3% 40px 3%;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1); 
    /* flex-shrink: 1; */
    z-index: 2;
    /* margin-bottom: -25%; */
    background-color: white;
    opacity: 1;
    transform: scale(1);
    transition: all ease 1s;
    height: 350px;
    overflow-y: hidden;
}

.box.mission{
    animation-duration: 1s;
    animation-name: fadeInDown;
    animation-delay: 5.3s;
    animation-fill-mode: both;
    
}

.box.mission ul{
    line-height: 1.5rem;
    margin: 30px auto;
    text-align: left;
    font-weight: 600;
    
}

.box.mission ul li{
    color: var(--dark-gray);
    list-style: none;
    font-family: "Kalam", sans-serif;
    margin-bottom: 10px;

}


.box.parish{
    animation-duration: 1s;
    animation-name: fadeInDown;
    animation-delay: 5s;
    animation-fill-mode: both;
    
}

.box.parish ul{
    line-height: 2rem;
}

.box.parish ul li{
    list-style: none;
    display: flex;
    align-items: center;
    line-height: 2rem;


}
.box.parish ul li a{
    text-decoration: none;
    cursor: pointer;
}

.box.parish ul li a:hover{
    color: var(--blue-color);
}

.box.parish ul li:hover{
    color: var(--blue-color);
}

.location_icon{
    margin-right: 10px;
}

.box.programme{
    animation-duration: 1s;
    animation-name: fadeInDown;
    animation-delay: 5.3s;
    animation-fill-mode: both;
}

.box.programme ul{
    line-height: 2rem;
}

.box.programme ul li{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2rem;
    /* margin-bottom: 5px; */


}

.box.programme ul li span{
    /* margin-top: -10px; */
    height: 0;
  transition: all 0.5s ease-in-out;
  overflow-y: hidden;
}

.box.programme ul li italic{
    /* margin-top: -10px; */
    height: 0;
    overflow-y: hidden;
  transition: all 0.5s ease-in-out;
  font-family: "Kalam", sans-serif;
}

.box.mission:hover {
    height: 800px;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2), 0 12px 12px rgba(0, 0, 0, 0.2); 
    z-index: 3;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1; */
    
}

.box.mission:hover .fade_box{
    animation-name: none;
    opacity: 0;
}
/* .box:hover{
cursor: pointer;
    transform: scale(2);
    padding-bottom: 70px;
    background-color: red;
    
} */

.fade_box span{
    color: var(--blue-color);
    transition: all ease 1s;
    font-size: 0.8rem;
    animation-iteration-count: infinite;
  transition: all 0.5s ease-in-out;
  
  animation-duration: 2s;
  animation-name: fading;
}

.fade_box{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9290091036414566) 21%, rgba(255,255,255,1) 100%);    position:absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 90%
}

.box.programme:hover {
    height: 500px;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2), 0 12px 12px rgba(0, 0, 0, 0.2); 
    /* z-index: 3; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1; */
    
}

.box.programme:hover .fade_box{
    animation-name: none;
    opacity: 0;
}

.box.programme:hover  .programme_time{
    margin-top: -10px;
    height: 30px;
}

.box h3{
    color: black;
    margin: 2rem;
    text-align: center;
}

.box p{
    line-height: 2rem;
}


.box_icon{
    margin-top: 1rem;
}

.about_content{
    margin: 5vh 15vw;
}

.about_content p{
    font-size: 1.4rem;
    line-height: 3rem;
    font-family: "Kalam", sans-serif;
    color: var(--dark-gray);
}

footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5vh 15vw;
    background-color: var(--light-gray);
    color: var(--dark-gray);
    align-items: center;

}

.footer_address{
    font-size: 1.2rem;
}

.footer_contact{
    text-align: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.footer_contact h1{
    margin-bottom: 20px;
}

.footer_contact p{
    margin-bottom: 5px;
}

.socials{
    display: flex;
}
.facebook_icon{
    cursor: pointer;
    margin-right: 15px;

}
.facebook_icon:hover{
    opacity: 0.8;
}

a{
    color: var(--dark-gray);
}

@media(max-width: 800px){
    .header{
        
        height: 150vh;
     
    }
   
    
    
    
    .header::after{
      
      height: 150%;
    }
    .header_tab img{
        height: 100px;
        margin: 5vh;
    }
    .header_heading, .header_sub_heading{
        width: 70%;
        text-align: center;
    }
    .header_heading{
        font-size: 2rem;
        
    }


    .form-control.num{
        display: flex;
        width: 70%;
    }

    .header_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .header_heading strong{
        font-size: 2.2rem;
        font-family: "Kalam";
        color: var(--blue-color);
    }
    blockquote {
        font-size: 1.3em;
        width: 70%;
        bottom: 10%;
        position: absolute;
    }
    .header_sub_heading{
        font-size: 1.rem;
        font-family: "Kalam", sans-serif;
        color: var(--light-gray);
    }
    #registration-form{
        width: 500px;
        /* width: 70%; */
        /* background-color: rgba(82, 1, 102, .9); */
        margin: 20px auto;
        /* padding: 50px; */
        margin-bottom: -10%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .register-btn{
        width: 70%;
    }
    .form-control {
        position: relative;
        width: 70%;
        /* min-width: 50%; */
        flex-grow: 1;
        margin-right: 20px;
        background-color: white;
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .form-control2 {
        /* position: relative; */
        margin: 10px 0 20px;
        color: #53586b;
        width: 300px;
        margin-right: 20px;
        flex-grow: 1;
        flex-direction: column;
        
    
    }
    
    .form_row{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .form-control.num{
        display: flex;
        flex-direction: row;
    }
    .address_state{
        display: flex;
        flex-direction: row;
    }
    .boxes{
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
        width: 70%;
        transform: translateY(0);
    }
    .box{
        width: 100%;
        position: relative;
    font-size: 1rem;
    padding: 20px 15% 40px 15%;

    z-index: 2;
    margin: 5% 0;
    background-color: white;

    }

    .box h3{
        margin: 0;
    }

    .box p{
        line-height: 1.5rem;
    }

    .parish{
        height: 550px;
        
        
    }

    .box.mission:hover {
        height: 1000px;
        
    }

/* --------SECTION STARTS HERE--------------- */



.form-control.dob, .ani{
    
    flex-direction: row;
}

/* --------SECTION STARTS HERE--------------- */



    footer{
        /* display: flex; */
        justify-content: space-between;
        width: 100%;
        padding: 5vh 15vw;
        background-color: var(--light-gray);
        color: var(--dark-gray);
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .facebook_icon{
        cursor: pointer;
        margin-bottom: 2vh;
    
    }

    .footer_contact {
        text-align: center;
    }
}


@media(max-height: 720px){
    .header{
        
        height: 170vh;
     
    }
    
    
    
    .header::after{
      
      height: 180%;
    }
    #registration-form{
       
            max-width: 700px;
            margin: 0 auto;
            
    }

    
   
    
}

@media(max-height: 520px){
    .header{
        
        height: 220vh;
     
    }
    
    
    
    .header::after{
      
      height: 230%;
    }
  
    
}

@media(max-height: 420px){
    .header{
        
        height: 250vh;
     
    }
    
    
    
    .header::after{
      
      height: 260%;
    }
  
    
}

@media(max-height: 320px){
    .header{
        
        height: 290vh;
     
    }
    
    
    
    .header::after{
      
      height: 300%;
    }
  
    
}

@keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }


  @keyframes fading {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  
    50% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
  }


  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      -ms-transform: translateY(40px);
      transform: translateY(40px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0) rotate(45deg);
    }
  
    40% {
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px) rotate(45deg);
    }
  
    60% {
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px) rotate(45deg);
    }
  }