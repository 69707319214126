@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;500;600;700;800&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

:root{
  --blue-color: #2DB3FF;
  --light-gray: #DCDCDC;
  --dark-gray: #8D8D8D;
}

.App {
  text-align: center;
}

