.loginPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('https://res.cloudinary.com/midas-acadeny/image/upload/v1645796269/RCCG/186036023_5570487909659543_791727074303003985_n_eknx26.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;

    .link_login{

        text-decoration: none;
        z-index: 1;
        .bth{
            font-family: "Kalam", sans-serif;
            color: whitesmoke;

            &:hover{
                color: rgb(120, 183, 224);
            }
        }
    }

    .container{
        min-width: 350px;
        z-index: 1;
        padding: 20px;
        margin: 20px;
        // height: 350px;
        // border: gray 2px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(236, 236, 236, 0.664);
        border-radius: 5px;

        

        h2{
            color:rgb(29, 29, 29);
        }

        .login_form{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: transparent;
        }
        .email_password_input{
            position: relative;
            margin: 10px 0 20px;
            width: 70%;
            /* min-width: 50%; */
            // flex-grow: 1;
            background-color: white;
            transition: all .5s ease 0s;
            border-radius: 50px;
            border: white 2px solid;
            
            input{
                background-color: transparent;
                border: 0;
                display: block;
                z-index: 3;
                width: 100%;
                padding: 15px 15px;
                font-size: 14px;
            transition: all .5s ease 0s;
            color: var(--dark-gray);
            }
            label{
                position: absolute;
                left: 5%;
                top: 0;
                transform: translateY(-90%);
                font-family: "Kalam", sans-serif;
                color: rgb(46, 46, 46);
                /* font-weight: 500; */
            transition: all .5s ease 0s;
                z-index: 1;
                opacity: 1;
            }
           

            input:focus{
                outline: none;

            }
            input:focus + label{
                transform: translateY(-90%);
            }
            
            
            
        }
        
        .login_input:focus ~ .email_password_input{
           border-color: var(--blue-color);
       }

       .error-message-l {
        margin: 10px;
        text-align: center;
        color: red;
    }

    .success-message-l {
        margin: 10px;
        text-align: center;
        color: var(--blue-color);
    }
       
        .login_btn{
            font-weight: 600;
            text-decoration: none;
            border: 2px solid var(--blue-color);;
            -webkit-border-radius: 25px;
            border-radius: 25px;
            color: white;
            display: inline-block;
            font-size: 12px;
            letter-spacing: 3px;
            margin-top: 20px;
            margin-left: 0;
            padding: 12px 35px;
            background-color: var(--blue-color);
            cursor: pointer;
            // width: 100%;
            transition: all 0.4s ease-out;
            
            &:hover{
                opacity: 0.9;
                /* color: var(--dark-gray); */
                
            }
            &:active{
                transform: translate();
            }
        }
        .email_password_input:focus-within{
            border-color: var(--blue-color);
        }
    
    .login:hover:active{
        transform: scale(0.9);
        }
    
        
        
    }
    
}

.loginPage:after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #0e0e0ea6;
    height: 100%;
    width: 100%;
}
