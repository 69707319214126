.all-users{
    height: 100%;
    width: 100%;
    /* position: relative; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

}

.table-heading{
    font-weight: 700;
    display: flex;
    /* flex: 1; */

}

.all-users ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.single_user{
    position: relative;
}

.single_user:nth-of-type(odd){
    position: relative;
    background-color: rgb(235, 235, 235);
}

.single_user:hover{
    background-color: rgb(219, 219, 219);
}

.single_user.active{
    
}

.all-users ul li{
    list-style: none;
    text-align: left;
    /* border-bottom: 1px solid rgb(204, 204, 204); */
    flex: 1;
    overflow-x: scroll;
    padding-left: 15px;
    margin-top: 10px;
    padding-bottom: 10px;
    /* border: 1px solid black; */
    min-width: 150px;
}

.all-users ul li + li{
    flex: 1;
    
}

.all-users ul li + li + li{
    flex: 1;
    
}

.all-users ul li + li + li + li{
    flex: 1;
    overflow-x: scroll;
    
}

.user_checkbox{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    width: 80px;
    color: var(--dark-gray);
}

.delete_user:hover{
    cursor: pointer;
    color: red;

}

.update_user:hover{
    cursor: pointer;
    color: var(--blue-color);

}

.delete_user{
    margin-right: 15px;
    
}

.update_user{
    margin-right: 15px;

}