.dashboard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(247,243,250);
    background: linear-gradient(277deg, rgba(247,243,250,1) 0%, rgba(250,250,250,1) 100%);
    overflow-x: hidden;
}

.dashboard header{
    font-family: 'Poppins', sans-serif;
    width: 100vw;
    height: 35vh;
    color: white;
    position: relative;
    /* background: rgb(67,9,135);
background: linear-gradient(187deg, rgba(67,9,135,1) 0%, rgba(38,3,71,1) 30%, rgba(32,2,47,1) 50%, rgba(23,2,43,1) 70%, rgba(79,3,157,1) 100%); */

/* background: rgb(49,0,62); */
background: linear-gradient(187deg, #242732c4 0%, rgba(16,0,21,0.9) 30%, rgba(15,0,19,0.8) 50%, #242732c4 70%, #242732c4 100%);
}

.dashboard header::after{
    content: '';
        left: 0;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        /* background: rgb(49,0,62); */
background: linear-gradient(187deg, #242732c4 0%, rgba(58, 57, 53, 0.9) 30%, rgba(71, 58, 46, 0.8) 50%, #242732c4 70%, #242732c4 100%);
}

.dashboard header::before{
    content: '';
        left: 40%;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: url('https://res.cloudinary.com/midas-acadeny/image/upload/v1645600761/RCCG/Rccg_logo_wsnioo.png');
        background-size: 40%;
        background-repeat: no-repeat;
}

.dashboard-after-header{
    
    position: absolute;
    z-index: 10;
    left: 12.5%;
    width: 75%;
    height: 100%;
    margin: 0 center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-heading {
color: inherit;
}

/*--------------the search bar------------------*/

.qrcode{
    width: 250px;
    height: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 1px rgb(0, 0, 0) solid; */
    position: fixed;
    left: 25%;
    top: 0;
    z-index: 20000;
}

.qrcode.show{
    height: 250px; 
    cursor: pointer;
}

.search-bar{
    height: 50px;
    border-radius: 10px;
    position: relative ;
    z-index: 50;
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    
}

.btn-null p{
    cursor: pointer;

}

.btn-null p:hover{
    color: var(--blue-color);
}

.btn-null p:active{
    transform: scale(0.93);
}

.btn-null{
    border: transparent solid 2px;
}

.isearch {
    border: 0;
    z-index: 222;
    /* transform: translateX(-50%); */
    
}

.search-bar .isearch{
    border: 0;
    max-width: 200px;
    padding: 15px;
    background-color: var(--light-gray);
    font-size: 1em;
    height: 50px;
    border-radius: 15px;
    transition: width 0.3s ease;
    color: #000030;
}

.isearch:focus, .btn:focus{
    outline: none;
}

.user_searched{
    cursor: pointer;
}

.user_searched:hover{
background-color: var(--light-gray);

}

.search-bar .btn {
    cursor: pointer;
    margin: auto;
    background: whitesmoke;
    border: none;
    font-size: 24px;
    border-radius: 0 15px 15px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease;
}

/*--------------the search bar(end)------------------*/


.dashboard-heading h1{
    color: white;
    font-family: 'Poppins';
    /* font-weight: 200; */
    font-size: 3rem;
    /* text-transform: uppercase; */
text-align: left;
/* animation-delay: -1s; */
letter-spacing: .5rem;
/* z-index: 10; */
margin: 0 15px;
/* margin-top: ; */
}

.dashboard-heading h3{
    color: white;
    font-family: 'Poppins';
    /* font-weight: 200; */
    font-size: 1.5rem;
    /* text-transform: uppercase; */
text-align: left;
/* animation-delay: -1s; */
letter-spacing: .5rem;
/* z-index: 10; */
margin: 0 15px;
margin-top: 40px;
}


.overview{
    position: absolute;
    top: 35vh;
    width: 75vw;
    min-height: 100px;
    transform: translateY(-60%);
    display: flex;
    justify-content: space-between;
    padding: 15px;
    z-index: 20pull;
}

.overview .registered {
    background-color: whitesmoke;
    min-width: 250px;
    height: 100%;
    border-radius: 15px; 
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: #333;
    padding: 15px;
    padding-left: 90px;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(124, 30, 30, 0.03); 
    cursor: pointer;


}

.overview .registered h4{
    color: rgb(134, 134, 134);
    margin-bottom: 5px;
    font-weight: 400;
}

.overview .registered h1{
    color: #333;
    font-weight: 600;
    font-size: 1.7rem;
}

.ministers-list{
    /* width: 75vw; */
    height: 100vh;
    overflow: scroll;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: whitesmoke;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.07), 0 6px 6px rgba(124, 30, 30, 0.07); 
    padding: 70px;
    border-radius: 15px;
    /* opacity: 0; */
    transform: translateY(102%);
    transition: transform 0.3s ease-in-out;
}

.ministers-list.active{
    opacity: 1;
    transform: translateY(0);
}

.ministers-list.active.search{
    top: 25%;
    height: 75vh; 
    
}

.ministers-list.active.search .close-minister{
    opacity: 0;
    
}

.close-minister{
    transform: translateY(2%);
    cursor: pointer;
    position: sticky;
    top: 2%;
    left: -22%;
}

.download-btn{
    transform: translateX(20%);
}
.download-sheet{
    background-color: var(--blue-color);
    padding: 15px 50px;
    border-radius: 5px; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    border: none;
}

.download a button:hover{
    opacity: 0.95;
}

.download a button:active{
    transform: scale(0.98);
}

.download a{
    text-decoration: none;
}

.download {
    margin-top: 30px;
}

.all-users::-webkit-scrollbar {
    display: none;
  }

.attendance {
    padding: 30px;
    width: 100vw;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #333;
    background: rgb(247,243,250);
background: linear-gradient(277deg, rgba(247,243,250,1) 0%, rgba(250,250,250,1) 100%);
}

.attendance h1{
    margin-top: 15px;
    align-self: start;
    margin-left: 12.5vw;
}

.registered-users{
    margin-top: 50px;
    width: 75vw;
    height: 100vh;
    overflow: scroll;
    scrollbar-width: none;


}

.d-btn{
    background-color: rebeccapurple;
    padding: 10px 20px;
    border-radius: 5px; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    border: none;
    margin-right: 15px;
    margin-top: 15px;
}

.d-btn.active{
    background-color: rgb(48, 4, 92);
}
.daily-filter{
    display: flex;
    align-self: start;
    margin-left: 12.5vw;
    flex-wrap: wrap;
}

.daily-event-users p{
    margin-left: 4.5vw;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    color: #333;
    

}

@media(max-width: 800px){
    .overview{
        position: inherit;
        padding: 50px;
        min-height: 100px;
        transform: translateY(0);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 15px;
        background: rgb(247,243,250);
        background: linear-gradient(277deg, rgba(247,243,250,1) 0%, rgba(250,250,250,1) 100%);
    }
    .overview .registered {
        margin-bottom: 15px;
        
    
    
    }

    .search-bar{
        flex-direction: column;
    }
  }