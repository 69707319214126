*{
    padding: 0;
    margin: 0;
}

.userProfile{
    // background-color: var(--dark-gray);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('https://res.cloudinary.com/midas-acadeny/image/upload/v1645796269/RCCG/186036023_5570487909659543_791727074303003985_n_eknx26.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .container{

        position: absolute;
        left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

            #user-registration-form{
            width: 500px;
        /* width: 70%; */
            /* background-color: rgba(82, 1, 102, .9); */
            /* padding: 50px; */
            max-width: 700px;
            margin: 150px auto;
            margin-top: 50px;
            width: 100%;
            animation-duration: 1s;
            animation-name: fadeInUp;
            animation-fill-mode: both;
            position: absolute;


            .update-delete-buttons{
                display: flex;
                justify-content: space-between;

                .delete{
                    background-color: red;
                    border-color: red;
                }
            }
        }

    }
}

.userProfile:after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #0e0e0ea6;
    height: 100%;
    width: 100%;
}

.user-error{
    color: #fff;
}