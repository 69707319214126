
@import url('https://fonts.googleapis.com/css?family=Arapey');



section {
  padding: 3em;
  height: 100vh;
        opacity: 1;
        position: relative;
  box-sizing: border-box;
  width: 100vw;
  
  blockquote {
    font-size: 2.3em;
    width: 30%;
        opacity: 1;
        margin-top: 17%;
    position: absolute;
    // opacity: 0;
    // left: 40%
@media(max-width: 800px){
  width: 80%;
  bottom: 0;

}
    
    span {
      width: 100%;
      background: red;
      display: block;
        opacity: 1;
        height: 5px;
      margin-top: 20px;
    }
  }
  
  img {
    position: absolute;
  }
  
  img:nth-of-type(1) {
    width: 40%;
    right: 10%;
        opacity: 1;
        top: 20%;
    // transform: scale(2);
    // opacity: 0;

  }
  img:nth-of-type(2) {
    width: 25%;
        opacity: 1;
        right: 40%;
    top: 29%;
    margin-top: 15%;
  }

@media(max-width: 800px){
    img:nth-of-type(1) {
        width: 70%;
        right: 10%;
        top: 2%;
        // transform: scale(2);
        opacity: 1;
    
      }
      img:nth-of-type(2) {
        width: 55%;
        right: 40%;
        opacity: 1;
        top: 19%;
        margin-top: 15%;
      }
    
}

}
section:nth-child(odd) {
  background: #f1f1f1;
}
.sticky {
  opacity: 1;

  background:  white !important;
}

// .percentage {
//   position: absolute;
//   bottom: 10px;
//   left: 10px;
// }

#box {
  width: 100px;
  height: 100px;
  position: absolute;
  border: 5px solid lightgray;
  margin: auto;
  top: 0;  bottom: 0; right: 45%;
}

.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  
  .footer_top{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    

    .footer_top-logo{
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      img{
        width: 100%;
      }

      .footer_top-address{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 2rem;

        
      }

      @media screen and (max-width: 900px) {
        // align-items: center;
        margin-top: 2rem;
      }

    }

    .footer_top-get-in-touch{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      // background-color: black;
      // height: 100%;

     

      .footer_top-tel, .footer_top-email{
        display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.3s ease-in;
      margin-bottom: 0.5rem;
      
      &:hover{
        color: var(--blue-color);
      }
      
      a{
        transition: all 0.3s ease-in;
        &:hover{
          color: var(--blue-color);
        }
      }
      }

      @media screen and (max-width: 900px) {
        // align-items: center;
        margin-top: 2rem;
      }
    }

    .footer_top-quick-link{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      

      a{
      margin-bottom: 0.5rem;

        transition: all 0.3s ease-in;
        &:hover{
          color: var(--blue-color);
        }

      }

      @media screen and (max-width: 900px) {
        // align-items: center;
        margin-top: 2rem;
      }
    }
    
    @media screen and (max-width: 900px) {
      flex-direction: column;
      // flex: 1;
      width: 100%;
      align-items: center;
      
    }
  }

  .footer_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 2rem 0;
    padding: 2rem 0;
    margin-bottom: -2rem;
    border-top: var(--dark-gray) solid 1px;

    p{
      color: rgb(1, 16, 29);
    }
    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      
    }
  }
}

.p-text {
  font-size: 0.9rem;
  text-align: left;
  // color: var(--gray-color);
  line-height: 1.5;
  text-decoration: none;
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;
}

.b-icon{
  margin-right: 1rem;
}

.f-head{
  margin-bottom: 2rem;
  color: var(--blue-color);
}